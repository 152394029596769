import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';

// Imports of API.
import { getDesignatedAutoComplete } from '../../../api/modules/SearchAutocomplete';

import { SearchSuggestionsResult } from '../../Home/types';

const useSearchSuggestions = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestionsResult[]>([]);

  const isSuggestionsLoading = useMemo(
    () => isSearchOpen && searchSuggestions.length === 0,
    [isSearchOpen, searchSuggestions]
  );

  const clearSearchSuggestions = useCallback(() => {
    setIsSearchOpen(false);
    setSearchSuggestions([]);
  }, []);

  const handleGetSuggestions = useCallback(async (searchQuery: string) => {
    setSearchSuggestions([]);

    let suggestions: SearchSuggestionsResult[] = [];

    if (searchQuery.length < 2) {
      suggestions.push({
        rank: 0,
        type: 'placeholder',
        title: 'Enter 2 or more letters for suggestions',
        highlighted_title: 'Enter 2 or more letters for suggestions',
        autocomplete_type: 'sentence'
      });
      setSearchSuggestions(suggestions);
      return;
    }

    try {
      const res = await getDesignatedAutoComplete(searchQuery);
      const searchResultResponse = res.data.success;

      const searchResult = searchResultResponse.map((element: any) => ({
        title: element.title,
        type: 'suggestion',
        rank: 1
      }));

      suggestions = [...suggestions, ...searchResult];

      setSearchSuggestions(suggestions);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const handleKeyPress = useMemo(() => debounce(handleGetSuggestions, 175), [handleGetSuggestions]);

  return {
    searchSuggestions,
    isSearchOpen,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions
  };
};

export default useSearchSuggestions;
