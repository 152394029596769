import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grow,
  IconButton,
  MobileStepper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isArray } from 'lodash';
import BubbleIcon from '../../../assets/svgs/ChatRIA/BubbleIcon';
import {
  sectionChip,
  userAvatar,
  userText,
  subText,
  sectionStyle,
  userAvatarText,
  messageDivider,
  chatBubbleStyles
} from '../styles/styles';
import { caseConverter } from '../const';
import AuthContext from '../../../store/Auth/AuthProvider';
import MarkdownRenderer from './MarkdownBox';
import SectionSuggestions from './SectionSuggestion';
import { stringAvatar } from '../../../helpers/utils';

interface ChatProps {
  isDefault: boolean;
  items?: any[] | any;
  currentSection?: any;
  handleAddToReportAction?: any;
  handleVerifyAction?: any;
  verifyAnswer?: any;
  source?: string;
  handleSetHelpQuery?: any;
  queryLoading?: boolean;
  currentApplication?: string;
  disableChatActions?: boolean;
  isARIAMode?: boolean;
  handleNewSection?: any;
  bubbleIndex?: number;
  applicationMeta?: any;
  brandName?: any;
  documentReferencePages?: any;
  setOpenApplicationOption?: any;
  addedToReportList?: any;
  reportLoading?: boolean;
}

const Bubble = ({
  item,
  disableChatActions,
  queryLoading,
  handleSetHelpQuery,
  handleAddToReportAction,
  handleVerifyAction,
  verifyAnswer,
  currentApplication,
  currentSection,
  isARIAMode,
  handleNewSection,
  bubbleIndex,
  generatedSection,
  sectionStepper,
  documentReferencePages,
  openSectionSelection,
  anchorEl,
  handleSectionClose,
  sectionNavigation,
  source,
  getSourceMapping,
  setOpenApplicationOption,
  addedToReportList,
  reportLoading
}: any) => {
  const { currentUser } = useContext(AuthContext);

  switch (item.type) {
    case 'info':
      return (
        <>
          <Divider sx={messageDivider} />
          <Stack direction='row' mb={3} width='100%' sx={sectionStyle}>
            <Stack display='flex' flexDirection='row'>
              <Box height='60px' width='60px'>
                <BubbleIcon sx={chatBubbleStyles.bubbleIcon} />
              </Box>
              <Box>
                <Stack direction='column' overflow='hidden'>
                  <Typography sx={subText}>
                    You&apos;ve switched the source application to{' '}
                    <Tooltip title={item?.labelname}>
                      <b>{item?.labelname}&nbsp;</b>
                    </Tooltip>
                    {getSourceMapping(source?.toLowerCase())}
                  </Typography>
                  <Typography sx={subText}>
                    Feel free to ask any questions related to this document
                  </Typography>
                  <Typography sx={subText}>{item?.metadata}</Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </>
      );
    case 'section':
      return (
        <Divider sx={{ width: '100%', mb: 2 }}>
          <Chip label={caseConverter(item.text)} sx={sectionChip} />
        </Divider>
      );
    default:
      return (
        <Stack
          id={`chat-bubble-${item.id}`}
          direction='column'
          p={1}
          sx={chatBubbleStyles.chatBubbleContainer}>
          <Stack direction='column'>
            <Box mr={item.type === 'user' ? 0 : 1}>
              {item.type === 'user' && (
                <Stack display='flex' flexDirection='row' alignItems='start'>
                  <Avatar variant='rounded' sx={userAvatar}>
                    <Typography variant='caption' sx={{ fontSize: '11px', fontWeight: '700' }}>
                      {stringAvatar(currentUser?.['custom:user'])}
                    </Typography>
                  </Avatar>
                  <Stack display='flex' flexDirection='column'>
                    <Typography sx={userAvatarText}>You</Typography>
                    <Typography sx={userText}>{item.text}</Typography>
                  </Stack>
                </Stack>
              )}
            </Box>
            {item.type !== 'user' && (
              <MarkdownRenderer
                question={item.question}
                disableChatActions={disableChatActions}
                queryLoading={queryLoading}
                handleSetHelpQuery={handleSetHelpQuery}
                riaMode={item.riaMode}
                markdown={item.text}
                type={item.type}
                action={item?.action}
                pageNumber={item?.pageNumber}
                url={item?.url}
                originalUrl={item?.originalUrl}
                handleAddToReportAction={handleAddToReportAction}
                handleVerifyAction={handleVerifyAction}
                verifyAnswer={verifyAnswer}
                isError={item.error || false}
                isCanceled={item.canceled || false}
                generatedForApplication={item?.applicationNumber || ''}
                generatedForSection={item?.currentSection || ''}
                generatedForBrand={item?.brandName || ''}
                generatedForSource={item?.source || ''}
                generatedForCenter={item?.center || ''}
                currentApplication={currentApplication || ''}
                currentSection={currentSection || ''}
                chatBubbleId={item?.id || ''}
                isARIAMode={isARIAMode}
                sectionStepper={sectionStepper}
                bubbleIndex={bubbleIndex}
                inReport={item?.inReport || false}
                ariaPageMetaData={item?.ariaPageMetaData || {}}
                currentAriaState={item?.currentAriaState || {}}
                documentReferencePages={documentReferencePages}
                sectionNavigation={sectionNavigation}
                setOpenApplicationOption={setOpenApplicationOption}
                addedToReportList={addedToReportList}
                reportLoading={reportLoading}
              />
            )}
            {openSectionSelection && (
              <SectionSuggestions
                item={item}
                handleNewSection={handleNewSection}
                bubbleIndex={bubbleIndex}
                generatedSection={generatedSection}
                anchorEl={anchorEl}
                openSectionSelection={openSectionSelection}
                handleSectionClose={handleSectionClose}
              />
            )}
          </Stack>
        </Stack>
      );
  }
};
const ProductMeta = ({ applicationMeta }: any) => {
  if (applicationMeta && applicationMeta?.approvalDate && applicationMeta?.submissionId) {
    const sourceText =
      applicationMeta?.source.toLowerCase() === 'jp' ? 'PMDA' : applicationMeta?.source;
    return (
      <Typography variant='caption' sx={subText}>
        (Source: {sourceText}, Approval: {applicationMeta?.approvalDate}, Submission:{' '}
        {applicationMeta?.submissionId})
      </Typography>
    );
  }
  return null;
};
const ChatBubbles = ({
  isDefault,
  items = {},
  currentSection = '',
  handleAddToReportAction = () => {},
  handleVerifyAction = () => {},
  verifyAnswer = false,
  handleNewSection = () => {},
  source = '',
  currentApplication = '',
  handleSetHelpQuery = () => {},
  queryLoading = false,
  disableChatActions = false,
  isARIAMode = false,
  bubbleIndex = 0,
  applicationMeta = {},
  brandName,
  documentReferencePages,
  setOpenApplicationOption,
  addedToReportList,
  reportLoading
}: ChatProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [openSectionSelection, setOpenSectionSelection] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const ariaSourceMap: any = {
    eu: 'EPARs and EC',
    ca: 'HPFB Documents',
    us: 'SBAs',
    uk: 'NICE Documents',
    hpra: 'HPFB Documents',
    ct: 'Clinical Trial Documents',
    guidance: 'FDA Guidance',
    'ema-guidance': 'EMA Guidance',
    'canada-guidance': 'CA Guidance',
    'canada-consultation': 'CA Consultations',
    'fda-letters': 'FDA Warnings and Untitled Letters',
    chmp: 'CHMP Documents',
    adcomm: 'Advisory Committee Documents',
    'ema-pips': 'EMA PIPs',
    'fda-written-request': 'FDA WRs',
    jp: 'Package Insert',
    hma: '',
    'dd-tools': 'Drug Development Tools',
    euctr: 'EU Clinical Trials Register Documents',
    ema_orphan: 'EMA Orphan Designations',
    pdco: 'PDCO Meeting Documents'
  };

  const cardSourceMap: any = {
    eu: 'SmPC',
    ca: 'Monograph',
    us: 'USPI',
    hpra: 'SmPC',
    hma: 'SmPC'
  };
  const getSourceMapping = (sourceId: string) => {
    if (isARIAMode) {
      if (sourceId in ariaSourceMap) {
        return ariaSourceMap[sourceId];
      }
      return 'SBAs';
    }
    if (['us', 'eu', 'hpra', 'ca'].includes(sourceId)) {
      return cardSourceMap[sourceId];
    }
    if (sourceId in ariaSourceMap) {
      return ariaSourceMap[sourceId];
    }
    return 'SBAs';
  };

  const handleSectionChange = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenSectionSelection(true);
  };

  const handleSectionClose = () => {
    setOpenSectionSelection(false);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  useEffect(() => {
    if (isArray(items)) {
      setActiveStep(items.length - 1);
    }
  }, [items]);
  if (isDefault) {
    return (
      <Stack direction='row' mb='20px' width='100%' sx={sectionStyle}>
        <Stack display='flex' flexDirection='row'>
          <Box height='60px' width='60px'>
            <BubbleIcon sx={chatBubbleStyles.bubbleIcon} />
          </Box>
          <Box>
            <Stack direction='column' overflow='hidden'>
              <Typography sx={subText}>Hello, I&apos;m ChatRIA</Typography>
              <Typography sx={subText}>
                Ask me questions from{' '}
                <Tooltip title={applicationMeta?.brandName ?? brandName}>
                  <b>{applicationMeta?.brandName ?? brandName}&nbsp;</b>
                </Tooltip>
                {getSourceMapping(source?.toLowerCase())}
              </Typography>
              <ProductMeta applicationMeta={applicationMeta} />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    );
  }
  if (isArray(items)) {
    const sectionStepper = isARIAMode ? null : (
      <Stack
        direction='row'
        justifyContent='space-between'
        flexWrap='wrap'
        width='100%'
        mb={0.5}
        sx={chatBubbleStyles.sectionContainer}
        alignItems='center'>
        <Typography
          sx={chatBubbleStyles.sectionText}
          onClick={event => {
            handleSectionChange(event);
          }}>
          Section: {caseConverter(items[activeStep]?.currentSection)}
          <Tooltip title='Click here to change section'>
            <IconButton
              sx={{
                width: '16px',
                height: '16px'
              }}>
              <KeyboardArrowDownIcon
                sx={{
                  pl: '2px',
                  width: '16px',
                  height: '16px'
                }}
              />
            </IconButton>
          </Tooltip>
        </Typography>
      </Stack>
    );

    const sectionNavigation = isARIAMode ? null : (
      <Stack display='flex' width='100%' alignItems='center'>
        <Grow in={items.length > 1} unmountOnExit>
          <MobileStepper
            variant='text'
            steps={items.length}
            position='static'
            activeStep={activeStep}
            sx={{
              p: 0,
              background: '#FBFBFB'
            }}
            nextButton={
              <Button
                sx={{ width: '7.778px', height: '12.728px' }}
                onClick={handleNext}
                disabled={activeStep === items.length - 1}>
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                sx={{ width: '7.778px', height: '12.728px' }}
                onClick={handleBack}
                disabled={activeStep === 0}>
                <KeyboardArrowLeft />
              </Button>
            }
          />
        </Grow>
      </Stack>
    );

    return (
      <Box>
        <Bubble
          key={`chat-answer-${items[activeStep]?.id}`}
          item={items[activeStep]}
          disableChatActions={disableChatActions}
          queryLoading={queryLoading}
          handleSetHelpQuery={handleSetHelpQuery}
          handleAddToReportAction={handleAddToReportAction}
          handleVerifyAction={handleVerifyAction}
          verifyAnswer={verifyAnswer}
          currentApplication={currentApplication || ''}
          currentSection={currentSection || ''}
          isARIAMode={isARIAMode}
          handleNewSection={handleNewSection}
          bubbleIndex={bubbleIndex}
          generatedSection={items.map(item => item.currentSection)}
          sectionStepper={sectionStepper}
          documentReferencePages={documentReferencePages}
          openSectionSelection={openSectionSelection}
          anchorEl={anchorEl}
          handleSectionClose={handleSectionClose}
          sectionNavigation={sectionNavigation}
          source={source}
          getSourceMapping={getSourceMapping}
          setOpenApplicationOption={setOpenApplicationOption}
          addedToReportList={addedToReportList}
          reportLoading={reportLoading}
        />
      </Box>
    );
  }
  return (
    <Bubble
      item={items}
      disableChatActions={disableChatActions}
      queryLoading={queryLoading}
      handleSetHelpQuery={handleSetHelpQuery}
      handleAddToReportAction={handleAddToReportAction}
      handleVerifyAction={handleVerifyAction}
      verifyAnswer={verifyAnswer}
      currentApplication={currentApplication || ''}
      currentSection={currentSection || ''}
      isARIAMode={isARIAMode}
      handleNewSection={handleNewSection}
      documentReferencePages={documentReferencePages}
      openSectionSelection={openSectionSelection}
      source={source}
      getSourceMapping={getSourceMapping}
      setOpenApplicationOption={setOpenApplicationOption}
      addedToReportList={addedToReportList}
      reportLoading={reportLoading}
    />
  );
};

ChatBubbles.defaultProps = {
  items: {},
  currentSection: '',
  handleAddToReportAction: () => {},
  handleVerifyAction: () => {},
  verifyAnswer: false,
  handleNewSection: () => {},
  source: '',
  currentApplication: '',
  handleSetHelpQuery: () => {},
  queryLoading: false,
  disableChatActions: false,
  isARIAMode: false,
  bubbleIndex: 0,
  applicationMeta: {},
  brandName: '',
  documentReferencePages: {},
  setOpenApplicationOption: null,
  addedToReportList: [],
  reportLoading: false
};
export default React.memo(ChatBubbles);
