const showLatestLabel = (data: any, source: string) => {
  if (source === 'us' && data?.application_type === 'EUA') {
    return false;
  }
  if (source === 'ct' || source === 'euctr') {
    return false;
  }
  return true;
};

export default showLatestLabel;
