import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { debounce } from 'lodash';
import axios, { CancelTokenSource } from 'axios';

// Imports of API.
import { getAutoCompleteNewDefault } from '../../../api/modules/SearchAutocomplete';

// types
import { SearchSuggestionsResult, SourceDropdown } from '../types';

import { createSourceModulePayloadMapping } from '../utils';

const useSearchSuggestions = (selectedSources: SourceDropdown[]) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<SearchSuggestionsResult[]>([]);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const [isResultsLoading, setIsResultsLoading] = useState(false);

  const isSuggestionsLoading = useMemo(
    () => isSearchOpen && isResultsLoading,
    [isSearchOpen, searchSuggestions, isResultsLoading]
  );

  const clearSearchSuggestions = useCallback(() => {
    setIsSearchOpen(false);
    setSearchSuggestions([]);
  }, []);

  const handleGetSuggestions = useCallback(
    async (textValue: string) => {
      setIsResultsLoading(true);

      // Cancel the previous request if it exists
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }

      // Create a new cancel token source
      cancelTokenSourceRef.current = axios.CancelToken.source();

      setSearchSuggestions([]);
      let suggestions: SearchSuggestionsResult[] = [];
      try {
        const convertedSelectedSources: any = createSourceModulePayloadMapping(selectedSources);

        selectedSources.forEach(sourceDropdown => {
          sourceDropdown.module.forEach(module => {
            const { sourceValue, value } = module;

            if (!convertedSelectedSources[sourceValue]) {
              convertedSelectedSources[sourceValue] = [];
            }

            convertedSelectedSources[sourceValue].push(value);
          });
        });

        const payload = {
          source: convertedSelectedSources
        };

        const res = await getAutoCompleteNewDefault(
          encodeURI(textValue) ?? "''",
          payload,
          cancelTokenSourceRef.current.token
        );

        const searchResultResponse = (res.data.body as SearchSuggestionsResult[]) ?? [];
        suggestions = [...suggestions, ...searchResultResponse];
        setSearchSuggestions(suggestions);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsResultsLoading(false);
      }
    },
    [selectedSources]
  );

  useEffect(() => {
    handleGetSuggestions('');
  }, []);

  const handleKeyPress = useMemo(() => debounce(handleGetSuggestions, 200), [handleGetSuggestions]);

  return {
    searchSuggestions,
    isSearchOpen,
    isSuggestionsLoading,
    setIsSearchOpen,
    handleKeyPress,
    clearSearchSuggestions
  };
};

export default useSearchSuggestions;
