import { createSvgIcon } from '@mui/material';

const TimePastIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <path
      d='M6 0C4.52293 0.00446819 3.09914 0.55227 2 1.539V0H1V2.5715C1.00026 2.81767 1.09817 3.05369 1.27224 3.22776C1.44631 3.40183 1.68233 3.49974 1.9285 3.5H4.5V2.5H2.4395C3.25213 1.67679 4.3254 1.1613 5.47594 1.04161C6.62647 0.92193 7.78286 1.20548 8.74753 1.84381C9.7122 2.48214 10.4253 3.43564 10.7649 4.54139C11.1046 5.64714 11.0497 6.83653 10.6097 7.90632C10.1697 8.97612 9.37192 9.85993 8.35259 10.4068C7.33326 10.9536 6.15567 11.1295 5.02104 10.9044C3.88641 10.6793 2.86514 10.0672 2.13174 9.1727C1.39833 8.27818 0.998304 7.15674 1 6H0C0 7.18669 0.351894 8.34673 1.01118 9.33342C1.67047 10.3201 2.60754 11.0892 3.7039 11.5433C4.80026 11.9974 6.00666 12.1162 7.17054 11.8847C8.33443 11.6532 9.40353 11.0818 10.2426 10.2426C11.0818 9.40353 11.6532 8.33443 11.8847 7.17054C12.1162 6.00666 11.9974 4.80026 11.5433 3.7039C11.0892 2.60754 10.3201 1.67047 9.33342 1.01118C8.34673 0.351894 7.18669 0 6 0V0Z'
      fill='currentColor'
      fillOpacity='0.7'
    />
    <path
      d='M5.5 3.49951V6.20651L7.1465 7.85301L7.85349 7.14601L6.5 5.79251V3.49951H5.5Z'
      fill='currentColor'
      fillOpacity='0.7'
    />
  </svg>,
  'TimePast'
);

export default TimePastIcon;
